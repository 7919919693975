import Glide, {
  Controls, Breakpoints, Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import { debounce } from './common';

(() => {
  let startAt = 0;
  let slidesLength = 0;
  let subnavContainer = null;
  const glideSettings = {
    type: 'slider',
    dragDistance: false,
    gap: 22,
    perView: 7,
  };

  const initGlide = (glide) => {
    const cw = document.documentElement.clientWidth;
    const toUpdate = { startAt, perView: 7, disable: false };
    subnavContainer.classList.remove('hide-slide-nav');
    switch (true) {
      case (cw < 480):
        toUpdate.perView = 2;
        toUpdate.disable = slidesLength < 2;
        break;
      case (cw >= 480 && cw < 768):
        toUpdate.perView = 3;
        toUpdate.disable = slidesLength < 3;
        break;
      case (cw >= 768 && cw < 992):
        toUpdate.perView = 5;
        toUpdate.disable = slidesLength < 5;
        break;
      case (cw >= 992 && cw < 1200):
        toUpdate.perView = 6;
        toUpdate.disable = slidesLength < 6;
        break;
      default:
        toUpdate.startAt = 0;
        toUpdate.disable = true;
    }
    if (toUpdate.disable) {
      subnavContainer.classList.add('hide-slide-nav');
      toUpdate.startAt = 0;
    }
    glide.update({
      startAt: toUpdate.startAt,
      perView: toUpdate.perView,
    });
    if (toUpdate.disable) {
      glide.disable();
    } else {
      glide.enable();
    }
  };

  const subNavs = document.querySelectorAll('#header-sub-nav li');
  if (subNavs.length > 0) {
    subnavContainer = document.querySelector('#header-sub-nav');
  }
  slidesLength = subNavs.length;
  subNavs.forEach((item, i) => {
    if (item.classList.contains('active')) {
      startAt = i;
    }
  });

  const navGlide = new Glide('.sub-navigation', glideSettings);

  if (subNavs.length > 0) {
    navGlide.mount({ Controls, Breakpoints, Swipe });
    initGlide(navGlide);
  }

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      if (subNavs.length > 0) {
        initGlide(navGlide);
      }
    }, 250);
  });
})(Glide, Controls, Breakpoints, Swipe, debounce);
