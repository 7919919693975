import Tobi from 'rqrauhvmra__tobi';

// eslint-disable-next-line
(() => {
  const $modalContainers = document.querySelectorAll('.modal-container');
  const $modals = document.querySelectorAll('.svg-lightbox.modal');
  const $clones = [];
  $modals.forEach(elem => $clones.push(elem.cloneNode(true)));

  const tobi = new Tobi({
    captionAttribute: 'title',
    zoom: false,
  });
  $clones.forEach((elem, index) => {
    elem.removeAttribute('id');
    elem.classList.remove('modal');
    $modalContainers[index].append(elem);
  });

  function openModal() {
    const i = this.dataset.index;
    const index = Array.from($modalContainers).findIndex(el => el.dataset.index === i);
    tobi.open(index);
  }

  $modalContainers.forEach((elem) => {
    elem.addEventListener('click', openModal);
  });
})(Tobi);
