import { debounce } from './common';

(() => {
  /* -------------------------
   * cursor
   * ---------------------------- */

  const mouseCursor = document.querySelector('.desktop #mouse-cursor');
  const mouseX = event => event.clientX;
  const mouseY = event => event.clientY;
  const positionCursor = (event) => {
    const mouse = {
      x: mouseX(event),
      y: mouseY(event),
    };
    mouseCursor.style.transform = `translate3d(${mouse.x}px, ${mouse.y}px, 0px)`;
  };

  if (mouseCursor) {
    document.addEventListener('mousemove', ev => debounce(positionCursor(ev)));
  }

  /* -------------------------
     * cursor animation
     * ---------------------------- */
  const addHoverClassToCursor = (enter) => {
    if (enter) {
      mouseCursor.classList.add('link-hovered');
    } else {
      mouseCursor.classList.remove('link-hovered');
    }
  };

  if (mouseCursor) {
    const hoverLinks = document.querySelectorAll('a, button, .mouse-hover');
    hoverLinks.forEach((link) => {
      link.addEventListener('mouseenter', () => addHoverClassToCursor(true));
      link.addEventListener('mouseleave', () => addHoverClassToCursor(false));
    });
  }

  /* -------------------------
    * toggle main navigation
    * ---------------------------- */
  const burger = document.querySelector('#burger');
  const mainNav = document.querySelector('#main-nav');
  const navOverlay = mainNav.querySelector('.nav-overlay');

  burger.addEventListener('click', () => {
    if (burger.classList.contains('open')) {
      burger.classList.remove('open');
      mainNav.classList.remove('open');
      navOverlay.classList.remove('open');
    } else {
      burger.classList.add('open');
      mainNav.classList.add('open');
      navOverlay.classList.add('open');
    }
  });

  navOverlay.addEventListener('click', () => {
    burger.classList.remove('open');
    mainNav.classList.remove('open');
    navOverlay.classList.remove('open');
  });

  navOverlay.addEventListener('click', () => {
    burger.classList.remove('open');
    mainNav.classList.remove('open');
  });

  /* -------------------------
   * accordion animation
   * ---------------------------- */
  let openedIndex = 0;
  const handleAccordionClick = (item, accordions, contents) => {
    const i = item.dataset.index;
    if (typeof item.dataset.toggle !== 'undefined' && openedIndex !== i) {
      accordions[openedIndex].classList.remove('open');
      contents[openedIndex].style.maxHeight = 0;
      openedIndex = i;
    }
    if (accordions[i].classList.contains('open')) {
      accordions[i].classList.remove('open');
      contents[i].style.maxHeight = 0;
    } else {
      accordions[i].classList.add('open');
      contents[i].style.maxHeight = `${contents[i].scrollHeight}px`;
    }
  };
  const accordions = document.querySelectorAll('main .accordion');
  const accordionTitles = document.querySelectorAll('main .accordion-title');
  const accordionContents = document.querySelectorAll('main .accordion-content');

  accordionTitles.forEach(item => item.addEventListener('click', () => {
    handleAccordionClick(item, accordions, accordionContents);
  }));
})(debounce);
